//- Template Variable
//- ----------------------------------------------

$template: soccer;


//- Base
//- ----------------------------------------------

$font-family-base:   'Source Sans Pro', sans-serif !default;
$font-family-accent: 'Montserrat', sans-serif !default;
$base-font-size:     15px !default;
$base-line-height:   26px !default;
$font-size-mobile:   13px !default;
$line-height-mobile: 22px !default;
$body-font-weight:   400 !default;

$font-size-sm:         ($base-font-size * .857) !default;

//- Colors
//- ----------------------------------------------

$color-primary:        #38a9ff !default;
$color-primary-darken: #1892ed !default;
$color-dark:           #1e2024 !default;
$color-dark-lighten:   #292c31 !default;
$color-gray:           #9a9da2 !default;
$color-2:              #31404b !default;
$color-3:              #07e0c4 !default;
$color-4:              #c2ff1f !default;
$color-4-darken:       #9fe900 !default;

$color-success:        #24d9b0 !default;
$color-info:           #0cb2e2 !default;
$color-warning:        #ff7e1f !default;
$color-danger:         #f34141 !default;

$color-white:          #fff !default;

// Duotone
$duotone-layer-dark: $color-dark !default;
$duotone-base-color-1: $color-dark !default;
$duotone-base-color-2: $color-dark-lighten !default;

$body-bg-color:           #edeff4;
$body-font-color:         $color-gray;
$body-font-color-inverse: $color-gray;

$link-color:            $color-primary-darken;
$link-decoration:       none;
$link-color-hover:      $color-primary;
$link-decoration-hover: none;

// Social
$social-facebook:      #3B5998 !default;
$social-twitter:       #1DA1F2 !default;
$social-google:        #DC4E41 !default;
$social-instagram:     #E4405F !default;
$social-twitch:        #6441A4 !default;
$social-youtube:       #FF0000 !default;
$social-linkedin:      #0077B5 !default;
$social-vk:            #6383A8 !default;
$social-odnoklassniki: #F4731C !default;
$social-pinterest:     #BD081C !default;
$social-rss:           #ffcc00 !default;
$social-calendar:      #0e95f3 !default;

$social-links: ();
$social-links: map-merge((
	"facebook":      $social-facebook,
	"fb":            $social-facebook, // alias
	"twitter":       $social-twitter,
	"instagram":     $social-instagram,
	"google":        $social-google,
	"twitch":        $social-twitch,
	"youtube":       $social-youtube,
	"linkedin":      $social-linkedin,
	"vk":            $social-vk,
	"odnoklassniki": $social-odnoklassniki,
	"pinterest":     $social-pinterest,
	"rss":           $social-rss
), $social-links);
// stylelint-enable


//- Components
//- ----------------------------------------------

$padding-base-vertical:     12px;
$padding-base-horizontal:   16px;


//- Card
//- ----------------------------------------------

$card-bg:                     #fff !default;
$card-header-bg:              transparent !default;
$card-subheader-bg:           #f5f7f9 !default;
$card-header-border-width:    6px !default;
$card-header-font-size:       16px !default;
$card-header-text-transform:  uppercase !default;
$card-header-min-height:      72px !default;
$card-header-padding-top:     22px !default;
$card-border-color:           #e4e7ed !default;
$card-border-radius:          4px !default;
$card-horizontal-padding:     24px !default;
$card-vertical-padding:       24px !default;
$card-horizontal-padding-lg:  43px !default;
$card-vertical-padding-lg:    43px !default;
$card-horizontal-padding-xlg: 70px !default;
$card-vertical-padding-xlg:   70px !default;
$card-bg-dotted:              url("../../assets/images/pattern-12x12.gif") !default;
$card-margin-vertical:        15px !default;


//- Jumbotron
//- ----------------------------------------------

$jumbotron-padding:                 2rem !default;
$jumbotron-bg:                      $card-bg !default;
$jumbotron-border-color:            $card-border-color !default;



//- Tables
//- ----------------------------------------------

$table-cell-padding:            13px;
$table-cell-padding-lg:         17px;
$table-condensed-cell-padding:  5px;
$table-bg:                      transparent;
$table-bg-accent:               #f9f9f9;
$table-bg-hover:                #f5f7f9;
$table-bg-active:               $table-bg-hover;
$table-font-color:              $body-font-color !default;
$table-font-weight:             400 !default;

$table-border-color:            #e4e7ed;
$table-thead-color:             $color-2;
$table-thead-bg-color:          #f5f7f9;
$table-thead-font-style:        normal !default;
$table-highlight:               $color-2;

$table-striped-bg:              rgba(0,0,0,.05) !default;


//- Buttons
//- ----------------------------------------------

$btn-font-weight:                700;
$btn-font-style:                 normal !default;
$btn-font-family:                $font-family-accent;

// Text Transform
$btn-text-transform:             uppercase !default;
$btn-text-transform-lg:          uppercase !default;
$btn-text-transform-sm:          uppercase !default;
$btn-text-transform-xs:          uppercase !default;

// Line-Height
$btn-line-height:                1.5em !default;
$btn-line-height-lg:             1.45em !default;
$btn-line-height-sm:             1.5em !default;
$btn-line-height-xs:             1.5em !default;

// Paddings
$btn-padding-vertical:           13px !default;
$btn-padding-horizontal:         42px !default;
$btn-padding-vertical-lg:        16px !default;
$btn-padding-horizontal-lg:      80px !default;
$btn-padding-vertical-sm:        10px !default;
$btn-padding-horizontal-sm:      38px !default;
$btn-padding-vertical-xs:        5px !default;
$btn-padding-horizontal-xs:      13px !default;

// Border Radius
$btn-border-radius:              2px;
$btn-border-radius-lg:           2px;
$btn-border-radius-sm:           2px;
$btn-border-radius-xs:           2px;

// Font Size
$btn-border-font-size:           11px;
$btn-border-font-size-lg:        13px;
$btn-border-font-size-sm:        11px;
$btn-border-font-size-xs:        9px;

// Button Default
$btn-default-color:              #fff !default;
$btn-default-color-hover:        #fff !default;
$btn-default-bg:                 $color-gray;
$btn-default-hover:              #868a91;
$btn-default-border:             transparent !default;
$btn-default-border-hover:       transparent !default;

// Button Primary
$btn-primary-color:              #fff !default;
$btn-primary-color-hover:        #fff !default;
$btn-primary-bg:                 $color-2;
$btn-primary-hover:              $color-primary-darken;
$btn-primary-border:             transparent !default;
$btn-primary-border-hover:       transparent !default;

// Button Primary - Inverse
$btn-primary-inverse-color:        #fff !default;
$btn-primary-inverse-color-hover:  #fff !default;
$btn-primary-inverse-bg:           $color-primary;
$btn-primary-inverse-hover:        $color-primary-darken;
$btn-primary-inverse-border:       transparent !default;
$btn-primary-inverse-border-hover: transparent !default;

// Button Success
$btn-success-color:              #fff !default;
$btn-success-color-hover:        #fff !default;
$btn-success-bg:                 $color-4-darken;
$btn-success-hover:              darken($color-4-darken, 3%);
$btn-success-border:             transparent !default;
$btn-success-border-hover:       transparent !default;

// Button Info
$btn-info-color:                 #fff !default;
$btn-info-color-hover:           #fff !default;
$btn-info-bg:                    $color-info !default;
$btn-info-hover:                 darken($color-info, 3%) !default;
$btn-info-border:                transparent !default;
$btn-info-border-hover:          transparent !default;

// Button Warning
$btn-warning-color:              #fff !default;
$btn-warning-color-hover:        #fff !default;
$btn-warning-bg:                 $color-warning !default;
$btn-warning-hover:              darken($color-warning, 3%) !default;
$btn-warning-border:             transparent !default;
$btn-warning-border-hover:       transparent !default;

// Button Danger
$btn-danger-color:               #fff !default;
$btn-danger-color-hover:         #fff !default;
$btn-danger-bg:                  $color-danger !default;
$btn-danger-hover:               darken($color-danger, 3%) !default;
$btn-danger-border:              transparent !default;
$btn-danger-border-hover:        transparent !default;

// Buttons Default Outline
$btn-o-default-color:            #9a9da2;
$btn-o-default-color-hover:      #fff;
$btn-o-default-border:           #dbdfe6;
$btn-o-default-border-hover:     $color-gray;
$btn-o-default-bg-hover:         $color-gray;

// Button Primary - Outline
$btn-o-primary-color:            $color-2;
$btn-o-primary-color-hover:      #fff;
$btn-o-primary-border:           #e4e7ed;
$btn-o-primary-border-hover:     $btn-primary-hover;
$btn-o-primary-bg-hover:         $btn-primary-hover;

// Button Primary Inverse - Outline
$btn-o-inverse-color:            #fff;
$btn-o-inverse-color-hover:      #fff;
$btn-o-inverse-border:           #fff;
$btn-o-inverse-border-hover:     $btn-primary-hover;
$btn-o-inverse-bg-hover:         $btn-primary-hover;

// Button Success - Outline
$btn-o-success-color:            #9a9da2 !default;
$btn-o-success-color-hover:      #fff !default;
$btn-o-success-border:           $btn-success-bg !default;
$btn-o-success-border-hover:     $btn-success-hover !default;
$btn-o-success-bg-hover:         $btn-success-hover !default;

// Button Info - Outline
$btn-o-info-color:               #9a9da2 !default;
$btn-o-info-color-hover:         #fff !default;
$btn-o-info-border:              $btn-info-bg !default;
$btn-o-info-border-hover:        $btn-info-hover !default;
$btn-o-info-bg-hover:            $btn-info-hover !default;

// Button Warning - Outline
$btn-o-warning-color:            #9a9da2 !default;
$btn-o-warning-color-hover:      #fff !default;
$btn-o-warning-border:           $btn-warning-bg !default;
$btn-o-warning-border-hover:     $btn-warning-hover !default;
$btn-o-warning-bg-hover:         $btn-warning-hover !default;

// Button Danger - Outline
$btn-o-danger-color:             #9a9da2 !default;
$btn-o-danger-color-hover:       #fff !default;
$btn-o-danger-border:            $btn-danger-bg !default;
$btn-o-danger-border-hover:      $btn-danger-hover !default;
$btn-o-danger-bg-hover:          $btn-danger-hover !default;


//- Typography
//- ----------------------------------------------

$font-size-h1: 48px;
$font-size-h2: 32px;
$font-size-h3: 24px;
$font-size-h4: 22px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$headings-font-family:    $font-family-accent !default;
$headings-color:          $color-2 !default;
$headings-color-inverse:  #fff !default;
$headings-line-height:    1.2 !default;
$headings-text-transform: uppercase !default;

$font-style-h1: normal;
$font-style-h2: normal;
$font-style-h3: normal;
$font-style-h4: normal;
$font-style-h5: normal;
$font-style-h6: normal;

$text-transform-h1: $headings-text-transform !default;
$text-transform-h2: $headings-text-transform !default;
$text-transform-h3: $headings-text-transform !default;
$text-transform-h4: none !default;
$text-transform-h5: $headings-text-transform !default;
$text-transform-h6: $headings-text-transform !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$line-height-base:        1.733333; // 26/15

$lead-font-size:   16px !default;
$lead-font-weight: 400 !default;

// UL
$ul-bullet-color: $color-primary !default;
$ul-bullet-symbol: "\f00c" !default;
$ul-bullet-symbol-size: 14px !default;

// OL
$ol-number-color: $color-2;

// Thumb
$thumb-border-radius: 0;


//- Forms
//- ----------------------------------------------

$input-label-color:          $headings-color !default;
$input-label-text-transform: none !default;
$input-bg:                   #fff !default;
$input-color:                $headings-color !default;
$input-height-base:          52px !default;
$input-border:               #e4e7ed !default;
$input-border-radius:        2px !default;
$input-font-size:            $base-font-size !default;

$input-bg-disabled:       #e4e7ed;
$input-border-disabled:   $input-border;

$input-color-focus:       $headings-color;
$input-bg-focus:          #fff;
$input-border-focus:      $color-primary;
$input-box-shadow-focus:  none;

$input-color-placeholder: rgba(154,157,162,0.6);

$form-group-margin-bottom: 22px;

$input-height-base-sm:   45px;
$input-font-size-sm:     13px;

// Form Control background
$input-success-bg-pos:      center right 11px, 100% 0 !default;
$input-success-bg-size:     15px 12px !default;
$input-success-bg-img:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='#{$color-success}' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E"), "#", "%23") !default;

$input-warning-bg-pos:      center right .9em !default;
$input-warning-bg-size:     20px 16px !default;
$input-warning-bg-img:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$color-warning}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"), "#", "%23") !default;

$input-danger-bg-pos:       center right 14px, 100% 0 !default;
$input-danger-bg-size:      11px 11px !default;
$input-danger-bg-img:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11'%3E%3Cpath transform='translate(-190.844 -2353.84)' fill='#{$color-danger}' d='M190.843,2355.96l2.121-2.12,9.193,9.2-2.122,2.12Zm9.192-2.12,2.122,2.12-9.193,9.2-2.121-2.12Z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Checkbox
$checkbox-bg:               $input-bg;
$checkbox-border:           #d8dce5;
$checkbox-border-radius:    $input-border-radius;
$checkbox-bg-active:        $color-primary-darken;
$checkbox-border-active:    $color-primary-darken;
$checkbox-bg-disabled:      $input-bg-disabled;
$checkbox-border-disabled:  #d8dce5;
$checkbox-symbol:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$color-white}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
$checkbox-symbol-bg-size:   60% 60% !default;
$checkbox-symbol-disabled:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$color-gray}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Radio Button
$radio-bg:                  $input-bg !default;
$radio-border:              #d8dce5 !default;
$radio-bg-disabled:         $input-bg-disabled !default;
$radio-border-disabled:     #d8dce5 !default;
$radio-symbol:              str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$color-primary}'/%3E%3C/svg%3E"), "#", "%23");

// Select
$select-caret: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-2}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23");

//- Tooltips
//- ----------------------------------------------

//** Tooltip text color
$tooltip-color:               #fff;
$tooltip-bg:                  $color-primary;
$tooltip-opacity:             1;

$tooltip-arrow-width:         5px;
$tooltip-arrow-color:         $tooltip-bg;


//- Accordion
//- ----------------------------------------------
$accordion-plus-bg:       $btn-default-bg !default;
$accordion-plus-bg-hover: $color-primary !default;


//- Form states and alerts
//- ----------------------------------------------

$state-success-text:             $color-success;
$state-success-bg:               #fff;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text:                $color-info;
$state-info-bg:                  #fff;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text:             $color-warning;
$state-warning-bg:               #fff;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text:              $color-danger;
$state-danger-bg:                #fff;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%);


//- Labels
//- ----------------------------------------------

$label-default-bg:            $color-gray;
$label-primary-bg:            $color-primary;
$label-success-bg:            $color-success;
$label-info-bg:               $color-info;
$label-warning-bg:            $color-warning;
$label-danger-bg:             $color-danger;

$label-color:                 #fff;
$label-link-hover-color:      #fff;
$label-border-radius:         .25em !default;


//- Modals
//- ----------------------------------------------

$modal-inner-padding:         15px;
$modal-title-padding:         15px;
$modal-title-line-height:     1.2em;
$modal-content-bg:                             $card-bg;
$modal-content-border-color:                   rgba(0,0,0,.2);
$modal-content-fallback-border-color:          #999;

$modal-backdrop-bg:           #000;
$modal-backdrop-opacity:      .9;
$modal-header-border-color:   #e5e5e5;
$modal-footer-border-color:   $modal-header-border-color;

$modal-lg:                    830px;
$modal-md:                    600px;
$modal-sm:                    300px;


//- Alerts
//- ----------------------------------------------

$alert-icon-width:            60px;

$alert-padding:               17px;
$alert-border-radius:         4px;
$alert-link-font-weight:      bold;
$alert-txt-accent-color:      $headings-color;
$alert-highlight-text-size:      11px !default;
$alert-highlight-text-transform: uppercase !default;

$alert-success-bg:            $state-success-bg;
$alert-success-icon-bg:       $state-success-text;
$alert-success-border:        #e4e7ed;

$alert-info-bg:               $state-info-bg;
$alert-info-icon-bg:          $state-info-text;
$alert-info-border:           #e4e7ed;

$alert-warning-bg:            $state-warning-bg;
$alert-warning-icon-bg:       $state-warning-text;
$alert-warning-border:        #e4e7ed;

$alert-danger-bg:             $state-danger-bg;
$alert-danger-icon-bg:        $state-danger-text;
$alert-danger-border:         #e4e7ed;


//- Progress Bars
//- ----------------------------------------------

$progress-bg:                 #ecf0f6;
$progress-bar-color:          #fff;
$progress-border-radius:      3px;
$progress-height:             6px !default;

$progress-bar-bg:             $color-primary;
$progress-bar-secondary-bg:   $color-4;
$progress-bar-success-bg:     $color-4-darken;
$progress-bar-warning-bg:     $color-warning;
$progress-bar-danger-bg:      $color-danger;
$progress-bar-info-bg:        $color-info;

$progress-label-color:        $color-2;
$progress-label-style:        normal !default;


//- Circular Bar
//- ----------------------------------------------

$circular-bar-label-color:    $headings-color;



//- Header
//- ----------------------------------------------

$header-bg: $color-dark;
$header-top-bg: $color-dark-lighten;
$header-secondary-bg: $color-dark;
$header-primary-bg: $color-dark-lighten;
$header-primary-alt-bg: $header-primary-bg !default; // used for Header Layout 4

// Top Bar
$top-bar-height:                    48px !default;
$top-bar-highlight:                 $color-primary;
$top-bar-link-color:                #fff !default;
$top-bar-text-color:                #6b6d70;
$top-bar-divider:                   true;
$top-bar-divider-color:             $top-bar-text-color;
$top-bar-arrow:                     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;
$top-bar-arrow-top:                 -2px !default;
$top-bar-arrow-inset-inline-start:  9px !default;
$top-bar-dropdown-inset-block-start: 40px !default;
$top-bar-dropdown-bg:               #fff;
$top-bar-dropdown-border:           #e4e7ed;
$top-bar-dropdown-shadow:           none;
$top-bar-dropdown-link-color:       $color-2;
$top-bar-dropdown-link-color-hover: $color-primary;

$top-bar-social-icon-color:         $color-gray;
$top-bar-social-icon-color-hover:   $color-primary;

// Info Block
$header-info-block-heading-size:      9px !default;
$header-info-block-cart-sum-font-size:   11px !default;
$header-info-block-cart-sum-font-weight: 400 !default;
$header-info-block-cart-sum-font-style:  normal !default;
$header-info-block-color:             $color-4;
$header-info-block-cart-sum-color:    $color-4;
$header-info-block-link-color-mobile: #6b6d70;

$header-info-block-color-mobile:             $color-primary;
$header-info-block-cart-sum-color-mobile:    $color-primary;

// Search Form
$header-search-width-desktop:       200px;
$header-search-width-desktop-lg:    360px;
$header-search-icon:                #fff;
$header-search-input-bg:            $color-dark-lighten;
$header-search-input-border:        $header-search-input-bg;
$header-search-input-bg-focus:      $color-dark-lighten;
$header-search-input-border-focus:  $header-search-input-bg-focus;

$header-search-input-bg-mobile:            $color-dark-lighten;
$header-search-input-border-mobile:        $header-search-input-bg-mobile;
$header-search-input-bg-focus-mobile:      $color-dark-lighten;
$header-search-input-border-focus-mobile:  $header-search-input-bg-focus-mobile;

// Mobile Header
$header-mobile-bg: $color-dark;
$header-mobile-height: 100px;
$header-mobile-logo-width: 97px;

// Nav
$nav-align:                right;
$nav-height:               62px;
$nav-font-family:          $font-family-accent;
$nav-text-transform:       uppercase;
$nav-font-weight:          700;
$nav-font-style:           normal !default;
$nav-font-size:            12px;
$nav-font-color:           #fff;
$nav-font-color-hover:     #fff;
$nav-active-border-height: 100%;
$nav-active-border-color:  $color-primary;
$nav-active-border-pos:    bottom;
$nav-active-bg:            transparent;
$nav-item-divider:         none;
$nav-item-padding:         0 34px !default;
$nav-arrow:                str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Submenu
$nav-sub-bg:               $color-dark;
$nav-sub-shadow:           none !default;
$nav-sub-font-family:      $font-family-accent;
$nav-sub-text-transform:   uppercase;
$nav-sub-font-weight:      700;
$nav-sub-font-style:       normal !default;
$nav-sub-font-size:        11px;
$nav-sub-arrow:            str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23");
$nav-sub-border-color:     #292c31;
$nav-sub-link-color:       #fff;
$nav-sub-hover-bg-color:   transparent;
$nav-sub-hover-link-color: $color-4;
$nav-sub-active-border-color: transparent !default;
$nav-sub-active-border-width: 0 !default;

// Megamenu
$nav-sub-megamenu-bg:                  $nav-sub-bg !default;
$nav-sub-megamenu-shadow:              none !default;
$nav-sub-megamenu-padding-vertical:   43px !default;
$nav-sub-megamenu-padding-horizontal: 55px !default;
$nav-sub-megamenu-link-color:       rgba(255,255,255,.4);
$nav-sub-megamenu-link-color-hover: #fff;
$nav-sub-megamenu-link-font-size:   11px !default;
$nav-sub-megamenu-link-font-weight: 700 !default;
$nav-sub-megamenu-link-font-style:  normal !default;
$nav-sub-megamenu-link-text-transform: uppercase !default;
$nav-sub-megamenu-title-color:      #fff;
$nav-sub-megamenu-post-title-color: #fff;
$nav-sub-megamenu-title-text-uppercase: uppercase !default;

// Mobile Nav
$nav-mobile-width: 270px;
$nav-mobile-bg: $color-dark;
$nav-mobile-color: #fff;
$nav-mobile-border: $color-dark-lighten;
$nav-mobile-font-size: 11px;
$nav-mobile-sub-bg: $color-dark-lighten;
$nav-mobile-sub-color: $color-gray;

// Header Cart
$header-cart-width:                    360px;
$header-cart-bg:                       #fff;
$header-cart-shadow:                   0 20px 40px rgba(0,0,0,0.08) !default;
$header-cart-border:                   #e4e7ed;
$header-cart-item-padding:             24px 24px 19px 24px !default;
$header-cart-item-border:              1px solid $header-cart-border !default;
$header-cart-product-cat-font-size:    8px !default;
$header-cart-product-cat-font-weight:  700 !default;
$header-cart-product-name-color:       $color-2;
$header-cart-product-name-color-hover: $color-primary;
$header-cart-product-name-font-style:  normal;
$header-cart-product-name-uppercase:   uppercase !default;
$header-cart-product-sum-size:         12px !default;
$header-cart-product-sum-color:        $color-2;
$header-cart-product-rating:           #ffdc11;
$header-cart-product-rating-empty:     #dbdfe6;
$header-cart-product-rating-size:      10px !default;
$header-cart-product-price-color:      $header-cart-product-name-color !default;
$header-cart-subtotal-size:            11px !default;
$header-cart-subtotal-text-transform:  uppercase !default;
$header-cart-subtotal-label:           $color-2;
$header-cart-subtotal-sum:             $header-cart-subtotal-label;
$header-cart-product-close:            $color-2;
$header-cart-thumb-border-radius:      0;
$header-cart-thumb-bg-color:           $color-dark !default;

// Pushy Panel
$pushy-toggle-icon-color: #fff;
$pushy-panel-width: 380px;
$pushy-panel-bg: #fff;
$pushy-panel-bg-dark: $color-dark-lighten;


//- Page Heading
//- ----------------------------------------------
$page-heading-padding-sm: 50px 0 !default;
$page-heading-padding-lg: 110px 0 106px 0;
$page-heading-bg-color: $color-dark;
$page-heading-bg-image-url: url("../images/soccer/page-heading.jpg");
$page-heading-bg-pattern-url: url("../images/page-heading-pattern.png");
$page-heading-bg-pattern-size: auto;
$page-heading-bg-pattern-repeat: no-repeat;
$page-heading-bg-pattern-opacity: 0.4;
$page-heading-font-size: 30px;
$page-heading-font-size-sm: 24px !default;
$page-heading-font-color: #fff !default;
$page-heading-font-highlight: $color-4;
$page-heading-font-style: normal;
$page-heading-text-transform: uppercase !default;


//- Event Heading
//- ----------------------------------------------
$event-header-bg-color: $card-bg;


//- Player Page Heading
//- ----------------------------------------------
$player-page-heading-bg-color: $color-dark;
$player-page-heading-bg-image-url: url("../images/soccer/page-heading.jpg");
$player-page-heading-bg-pattern-url: url("../images/page-heading-pattern.png");
$player-page-heading-bg-pattern-opacity: 0.4;
$player-page-heading-bg-pattern-size: auto;
$player-page-heading-bg-pattern-repeat: no-repeat;

$player-page-heading-second-name: $color-4;
$player-page-heading-title: lighten($color-primary, 20%);


// Breadcrumbs
$breadcrumb-font-size:         10px !default;
$breadcrumb-text-transform:    uppercase !default;
$breadcrumb-font-weight:       bold !default;
$breadcrumb-divider-color:     $color-4;
$breadcrumb-link-color:        $color-white;
$breadcrumb-link-hover-color:  $color-4;
$breadcrumb-link-active-color: rgba(255,255,255,.5);
$breadcrumb-item-padding:      5px !default;
$breadcrumb-divider:           quote("/") !default;


//- Filter
//- ----------------------------------------------
$filter-bg:                     #fff;
$filter-border:                 #e4e7ed;
$filter-active:                 $filter-bg !default;
$filter-title-font-size:        13px !default;
$filter-title-text-transform:   uppercase !default;
$filter-label-color:            $body-font-color;
$filter-label-font-size:        8px !default;
$filter-label-font-weight:      bold !default;
$filter-label-text-transform:   uppercase !default;
$filter-label-letter-spacing:   -0.02em !default;
$filter-label-position-top:     28px !default;
$filter-label-position-top-sm:  20px !default;
$filter-color:                  $color-2;
$filter-placeholder-font-style: normal !default;
$filter-padding-top:            32px !default;
$filter-padding-bottom:         24px !default;
$filter-padding-top-sm:         26px !default;
$filter-padding-bottom-sm:      12px !default;
$filter-padding-horizontal:     24px !default;
$filter-boxed-border-radius:    $card-border-radius !default;
$filter-caret:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-2}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23");
$filter-dropdown-bg: #fff;
$filter-dropdown-border: $filter-border;
$filter-dropdown-color: rgba($color-2, 0.4);
$filter-dropdown-color-hover: $color-2;
$filter-dropdown-font-size:       11px !default;
$filter-dropdown-font-style:      normal !default;


//- Content
//- ----------------------------------------------
$content-padding-vertical:    60px;
$content-padding-vertical-sm: 30px;


//- Widgets
//- ----------------------------------------------
$widget-title-text-transform: uppercase !default;


//- Blog
//- ----------------------------------------------
$post-category-1: $color-primary;
$post-category-2: $color-3;
$post-category-3: $color-4-darken;
$post-category-4: #f34141 !default;

$post-category-font-style: normal !default;

$post-title-color:            $color-2 !default;
$post-title-color-hover:      lighten($color-2, 15%) !default;
$post-title-font-style:       normal !default;
$post-title-text-transform:   $headings-text-transform !default;
$post-title-font-size-sm:     18px !default;
$post-title-font-size:        20px !default;
$post-title-font-size-lg:     24px !default;
$post-title-line-height:      1em !default;
$post-excerpt-font-size:      $base-font-size !default;
$post-excerpt-line-height:    $base-line-height !default;
$post-excerpt-padding-top:    23px !default;
$post-excerpt-padding-top-sm: 13px !default;

$post-quote-bg:         #194676;
$post-quote-highlight:  $color-4;
$post-quote-font-style: normal;
$post-quote-footer-font-style: normal;

$post-card-overlay-color: $color-2 !default;

$single-post-title-font-size-sm:   18px !default;
$single-post-title-font-size:      32px !default;
$single-post-title-line-height-sm: 1.0625em !default;
$single-post-title-line-height:    1.0625em !default;
$single-post-title-margin-bottom:  0.55em !default;


//- Sidebar
//- ----------------------------------------------
$sidebar-content-font-size:   14px;
$sidebar-content-line-height: 22px;

// Widget Tabbed
$widget-tabbed-nav-bg:                transparent !default;
$widget-tabbed-nav-font-size:         11px !default;
$widget-tabbed-nav-font-style:        normal !default;
$widget-tabbed-nav-font-color:        rgba($headings-color, .4) !default;
$widget-tabbed-nav-font-color-hover:  $headings-color !default;
$widget-tabbed-nav-font-color-active: $headings-color !default;

// Widget Twitter
$widget-twitter-icon-color:              #c2c4c7 !default;
$widget-twitter-thumb-size:              40px !default;
$widget-twitter-thumb-border-radius:     2px !default;
$widget-twitter-name-font-size:          15px !default;
$widget-twitter-name-font-size-sm:       12px !default;
$widget-twitter-name-font-weight:        400 !default;
$widget-twitter-name-font-style:         normal !default;
$widget-twitter-name-text-transform:     none !default;
$widget-twitter-username-font-size:      11px !default;
$widget-twitter-username-font-weight:    400 !default;
$widget-twitter-username-font-style:     normal !default;
$widget-twitter-username-text-transform: none !default;
$widget-twitter-username-color:          $body-font-color !default;
$widget-twitter-username-color-hover:    $color-2 !default;
$widget-twitter-txt-font-size:           14px !default;
$widget-twitter-txt-line-height:         22px !default;
$widget-twitter-txt-color:               #2bd0ff !default;
$widget-twitter-txt-color-hover:         darken(#2bd0ff, 15%) !default;
$widget-twitter-timestamp-font-size:     11px !default;
$widget-twitter-timestamp-color:         $body-font-color !default;
$widget-twitter-timestamp-color-hover:   $color-2 !default;

// Widget Results
$widget-results-title-font-size:         9px !default;
$widget-results-title-text-transform:    uppercase !default;
$widget-results-title-vertical-padding:  10px !default;

// Widget Featured Player
$widget-player-stats-value-size:           28px !default;
$widget-player-stats-value-color:          $headings-color !default;
$widget-player-stats-value-margin:        .25em !default;
$widget-player-stats-label-text-transform: uppercase !default;


//- Single Post
//- ----------------------------------------------
$single-post-author-avatar-size:          60px;
$single-post-author-avatar-border-radius: 50%;

$single-post-3-image:                     url("../images/soccer/samples/_soccer_single-post-img5.jpg");


//- Pagination
//- ----------------------------------------------
$pagination-margin:           20px 0 !default;
$pagination-bg:               #fff;
$pagination-color:            $color-2;
$pagination-font-size:        11px !default;
$pagination-border:           $card-border-color;
$pagination-bg-hover:         $color-primary-darken;
$pagination-color-hover:      #fff;
$pagination-border-hover:     $color-primary-darken;
$pagination-bg-active:        $color-primary-darken;
$pagination-color-active:     #fff;
$pagination-border-active:    $color-primary-darken;


//- Content Filter
//- ----------------------------------------------
$content-filter-bg:               $filter-bg !default;
$content-filter-border:           $filter-border !default;
$content-filter-padding-top:            28px !default;
$content-filter-padding-bottom:         28px !default;
$content-filter-padding-left:           20px !default;
$content-filter-padding-right:          20px !default;
$content-filter-padding-top-sm:         18px !default;
$content-filter-padding-bottom-sm:      18px !default;
$content-filter-color:            $body-font-color !default;
$content-filter-color-hover:      $color-2 !default;
$content-filter-bg-active:         transparent !default;
$content-filter-active-highlight: $color-primary !default;
$content-filter-label-font-size: 13px !default;
$content-filter-label-font-style: normal !default;
$content-filter-label-font-weight: 700 !default;
$content-filter-sublabel-font-size: 8px !default;
$content-filter-sublabel-font-style: normal !default;
$content-filter-sublabel-font-weight: 400 !default;


//- Team
//- ----------------------------------------------
$team-select-color: $color-2;
$team-select-arrow: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-2}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23");


//- Tournament
//- ----------------------------------------------
$tournament-team-bg-winner: $card-bg;
$tournament-divider: $card-bg;
$tournament-winner-icon: $color-2;


//- Event Heading
//- ----------------------------------------------
$event-header-bg-color: $card-bg;


//- Shop
//- ----------------------------------------------
$shop-price-filter-bar-color: $color-4-darken;
$shop-price-filter-handle-color: $color-2;

// Account
$account-nav-bg: transparent !default;
$account-nav-bg-hover: rgba(0,0,0,.03) !default;
$account-nav-color: $headings-color !default;
$account-nav-color-hover: $account-nav-color !default;
$account-nav-color-active: $headings-color !default;
$account-nav-border-width: 6px !default;
$account-nav-font-size: 12px !default;
$account-nav-subtitle-color: $body-font-color !default;
$account-nav-subtitle-font-weight: 400 !default;

$product-color-1: linear-gradient(to left top, #fe2b00, #f7d500);
$product-color-2: linear-gradient(to left top, #003e78, #33fff3);
$product-color-3: linear-gradient(to left top, #00adbd, #7cffd0);
$product-color-4: linear-gradient(to left top, #3f1464, #dd2f8d);
$product-color-5: linear-gradient(to left top, #140078, #4dcbff);
$product-color-6: linear-gradient(to left top, #006f3f, #bdff3d);

// Rating
$product-rating-color:          #ffdc11;
$product-rating-color-disabled: #dbdfe6;

// Cart
$product-base-font-size:      13px !default;
$product-name-font-size:      12px !default;
$product-name-font-size-lg:   13px !default;
$product-name-text-transform: uppercase !default;
$product-cat-font-size:       8px !default;
$product-cat-color:           $body-font-color !default;

// Filter
$shop-filter-height:              72px !default;
$shop-layout-filter-color:        #d7d8da;
$shop-layout-filter-color-active: $color-primary;

// Sale
$product-sale-bg: $color-primary;
$product-sale-color: #fff;



//- Footer
//- ----------------------------------------------
$footer-widgets-bg:        $color-dark;
$footer-secondary-bg:      #16171a;
$footer-secondary-side-bg: $color-primary;
$footer-font-size:         14px;
$footer-line-height:       22px;
$footer-font-color:        rgba($body-font-color, 0.6);

// Footer Widgets
$footer-widget-title:                #fff !default;
$footer-widget-title-font-size:      14px !default;
$footer-widget-title-font-style:     normal !default;
$footer-widget-title-margin:         3.5em !default;

$footer-widget-post-title-font-style:  normal;
$footer-widget-post-title-line-height: 1em;
$footer-widget-post-thumb-border-radius: 0;

$footer-widgets-padding-top:          30px !default;
$footer-widgets-padding-bottom:       30px !default;
$footer-widgets-padding-top-md:       60px !default;
$footer-widgets-padding-bottom-md:    70px !default;
$footer-widgets-padding-top-lg:       80px !default;
$footer-widgets-padding-bottom-lg:    96px !default;

// Footer Info Section
$footer-info-bg:        $color-dark;

// Contact Form
$footer-widget-contact-form-input-bg: #2b2e33;
$footer-widget-contact-form-input-border: #2b2e33;

// Sponsors
$sponsors-title-color: $color-white;

// Footer Copyright
$footer-copyright-font-size:    9px;
$footer-copyright-border-size:  0;
$footer-copyright-border-style: solid;
$footer-copyright-border-color: transparent;

$footer-copyright-padding:           24px;
$footer-copyright-padding-lg:        24px;
$footer-copyright-link-color:        #fff;
$footer-copyright-link-color-active: $color-primary;

// Footer Nav
$footer-nav-font-size:    11px;
$footer-nav-font-size-sm: 9px;
$footer-nav-color:        #fff;
$footer-nav-color-active: $color-primary;


//- SVG Icon
//- ----------------------------------------------
$svg-stroke: $color-4;
$footer-contact-info-icon-color: $color-4;


//- Info Block
//- ----------------------------------------------
$info-block-icon-color:  $svg-stroke;
$info-block-link-color:  #6b6d70;
$info-block-font-size:   9px !default;
$info-block-font-style:  normal !default;
$info-block-font-weight: 400 !default;


//- Icobox
//- ----------------------------------------------
$icobox-icon-border-color: #eaeced;
$icobox-icon-color-primary: $color-primary;
$icobox-icon-color-secondary: $color-4;
$icobox-title-font-size: 24px;
$icobox-title-font-style: normal;
$icobox-title-text-transform: uppercase;


//- Timeline
//- ----------------------------------------------
$timeline-marker-color: $color-primary;
$timeline-marker-line-color: $card-border-color;


//- noUI Range Slider
//- ----------------------------------------------
$range-bg-color: #dbdfe6;
$range-border-radius: 2px !default;
$range-handle-border-radius: 50% !default;
$range-top-padding: 18px !default;
$range-label-color: $headings-color !default;
$range-label-price-color: $headings-color !default;


//- Widget
//- ----------------------------------------------
$widget-decor-color-1: rgba(236, 240, 246, .6);
$widget-decor-color-2: rgba(0,0,0,.1);
$widget-decor-color-3: rgba(0,0,0,.11);


//- Slick Carousel
//- ----------------------------------------------
$slick-font-family: "Font Awesome 5 Free" !default;
$slick-loader-path: "./" !default;
$slick-arrow-bg-color: #999ea6;
$slick-arrow-color: white !default;
$slick-arrow-bg-color-hover: $color-primary-darken;
$slick-dot-color: #d7d8da !default;
$slick-dot-color-on-hover: $color-2 !default;
$slick-dot-color-active: $color-primary !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


//- Preloader
//- ----------------------------------------------
$preloader-bg: $color-dark;
$preloader-percent-color: #fff;
$preloader-percent-accent-color: $color-primary;
$preloader-loading-txt: "Cargando...";
$preloader-title: "Copa Malta Morena";

// For generating grid via class
$grid-gutter-width: 16px;
